<template>
  <v-layout
    class="guest_wrapper"
    column>
    <!-- Start guest menu -->
    <core-app-bar />
    <hero id="main" />

    <welcome id="whatsgo" />

    <core-footer />
    <!--<guest-menu
      :texts="texts.menu"
    />-->
    <!-- End guest menu -->
    <!-- Start language menu -->
    <!--<div
      class="language_options">
      <v-btn
        text
        fab
        small
        @click="toggle_language(0)">
        ES
      </v-btn>
      |
      <v-btn
        text
        fab
        small
        @click="toggle_language(1)">
        EN
      </v-btn>
    </div>-->
    <!-- End language menu -->
  </v-layout>
</template>
<script>
import page_texts from '../guests/guestTexts'

export default {
  data: () => ({
    templates: [
      '/static/templateExample.png',
      '/static/templateExample.png',
      '/static/templateExample.png',
      '/static/templateExample.png',
    ]
  }),
  beforeMount () {
    // toggle the VueX lang state 
    this.$store.commit('toggle_language', localStorage.getItem('lang'))
  },
  methods: {
    // method for toggle language :: changes localStorage "lang" and Vuex state language
    toggle_language (value) {
      this.$store.commit('toggle_language', value)
    }
  },
  components: {
    // import Menu
    GuestMenu: () => import('@/components/guest/Home/menu'),
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreFooter: () => import("@/components/core/Footer"),
    Demo: () => import("@/components/sections/Demo"),
    Hero: () => import("@/components/sections/Hero"),
    Welcome: () => import("@/components/sections/Welcome")
  },
  computed: {
    language () {
      // iniciatlize global language for this section ( 0 = spanish, 1 = english)
      return this.$store.state.language
    },
    texts () {
      // return the texts JSON based on the language chosen
      // if more languages are added, convert into a switch with param (this.language)
      return this.language == 0 ? page_texts.spanish:page_texts.english
    }
  }
}
</script>
<style>
@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url("../../assets/fonts/Helvetica.ttf"); /* IE */
}
  /** style for language options wrapper */
  .language_options {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1000
  }
  /** style for all section titles and descriptions in landing page */
  .section_title {
    font-family: 'Montserrat', sans-serif, sans-serif;
    text-align: center;
    font-weight: bold;
    margin-right: 8px;
    font-size: 25px
  }
  .section_description {
    font-family: 'Montserrat', sans-serif, sans-serif;
    text-align: center;
    font-weight: bolder;
    margin: 8px;
    font-size: 12px;
    max-width: 30%;
  }
  .description_mobile {
    max-width: 70% !important;
  }
  .variant_dark {
    color: #304C64
  }
  .variant_blue {
    color: #217BCC
  }
  /** style for sectionw rapper */
  .section_layout {
    z-index: 10;
    background-color: #FFFFFF;
    margin-bottom: 0px;
  }
  .margins_section {
    margin-top: 10%
  }
  /** style for setting background-color of whole section */
  .guest_wrapper {
    background-color: #FFFFFF
  }
</style>